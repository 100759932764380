<template>
  <section class="d-flex flex-row-reverse">
    <!-- Ссылки для промостраниц -->
    <div v-if="$route.name === 'PromoList'" class="d-flex">
      <a :href="LinksList[0].link"
        target="_blank"
        class="ml-0 ml-md-7 link">
          {{ LinksList[0].name }}
      </a>

      <a :href="LinksList[1].link" 
        target="_blank"
        class="ml-7 link">
          {{ LinksList[1].name }}
      </a>
    </div>

    <!-- Ссылки для промокарусели -->
    <div v-if="$route.name === 'PromoCarousel'" class="d-flex">
      <a :href="LinksList[2].link"
        target="_blank"
        class="ml-7 link">
          {{ LinksList[2].name }}
      </a>
    </div>
  </section>
</template>

<script>
import { Trans } from '@/plugins/Translation'

export default {
  name: 'TopLinks',
  computed: {
    country: function() {
      return Trans.currentLocale;
    },
    LinksList: function() {
      switch (this.country) {
        case 'kz':
          return [
            { 
              link: `${process.env.VUE_APP_AMWAY_KZ}/promo-page-test`, 
              name: 'Предпросмотр' 
            },
            {
              link: `${process.env.VUE_APP_AMWAY_KZ}/`, 
              name: 'Опубликованные' 
            },
            {
              link: `${process.env.VUE_APP_AMWAY_KZ}/`, 
              name: 'Страница карусели' 
            }
          ]
        case 'ua': 
          return [
            { 
              link: `${process.env.VUE_APP_AMWAY}/promo-page-test`, 
              name: 'Предпросмотр' 
            },
            {
              link: `${process.env.VUE_APP_AMWAY}/`, 
              name: 'Опубликованные' 
            },
            {
              link: `${process.env.VUE_APP_AMWAY}/`, 
              name: 'Страница карусели' 
            }
          ]
        default:
          return [
            { 
              link: `${process.env.VUE_APP_AMWAY}/promo-page-test`, 
              name: 'Предпросмотр' 
            },
            {
              link: `${process.env.VUE_APP_AMWAY}/`, 
              name: 'Опубликованные' 
            },
            {
              link: `${process.env.VUE_APP_AMWAY}/promo-new-test-page`, 
              name: 'Страница карусели' 
            }
          ]
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.link {
  color: #0A467E;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s ease;

  @media screen and ( max-width: 600px ) {
    font-size: 13px;
    line-height: 18px;
  }

  &:hover {
    text-decoration: underline;
  }
}
</style>
